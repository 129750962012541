import type { StyleFunctionProps } from '@chakra-ui/react'
import { cssVar, defineStyleConfig, Tooltip as ChakraTooltip } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import { COLORS } from 'theme/constants'

const TooltipArrowBackground = cssVar('popper-arrow-bg')
const TooltipArrowShadowColor = cssVar('popper-arrow-shadow-color')

const variants = {
  zircuitPrimary: (props: StyleFunctionProps) => ({
    bgColor: mode('white', 'dark1')(props),
    border: '1px solid',
    borderColor: mode('grey04', 'darkGrey04')(props),
    borderRadius: 10,
    color: mode('dark01', 'white')(props),
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    zIndex: 100,
    [TooltipArrowBackground.variable]: mode(COLORS.white, COLORS.dark1)(props),
    [TooltipArrowShadowColor.variable]: mode(COLORS.grey04, COLORS.darkGrey04)(props),
    px: 2.5,
    py: 1.5,
  }),
}

export const Tooltip = defineStyleConfig({
  defaultProps: { variant: 'zircuitPrimary' },
  variants,
})

// https://github.com/chakra-ui/chakra-ui/issues/1424#issuecomment-743342944
ChakraTooltip.defaultProps = { ...ChakraTooltip.defaultProps, hasArrow: true, placement: 'top' }
