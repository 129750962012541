import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  checkboxAnatomy.keys,
)

const baseStyle = definePartsStyle((props) => ({
  control: {
    borderWidth: 1.5,
    borderColor: mode('grey03', 'darkGrey03')(props),
    _checked: {
      bgColor: mode('zircuitPrimary', 'zircuitLight')(props),
      borderColor: mode('zircuitPrimary', 'zircuitLight')(props),
      _hover: {
        bgColor: mode('zircuitDark', 'zircuitPrimary')(props),
        borderColor: mode('zircuitDark', 'zircuitPrimary')(props),
      },
    },
  },
  icon: {
    color: 'white',
  },
}))

export const Checkbox = defineMultiStyleConfig({ baseStyle })
