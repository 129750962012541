import { defineChain } from '@reown/appkit/networks'
import { capitalize, keyBy, mapValues } from 'lodash'
import { env } from 'env.client'
import { TransactionListItemTxType as TxType } from 'generated/reactQueryClient'

export const ETH = {
  id: 0,
  name: 'Ethereum',
  symbol: 'ETH',
  image: 'eth', // Image name in assets folder
  decimals: 18,
  isManual: false,
} as const

// Roll ups.
export const EXPECTED_SEND_REQUEST_WITHDRAWAL_TIME_IN_SECONDS = 2
export const EXPECTED_PROVE_WITHDRAWAL_TIME_IN_SECONDS = 30
export const EXPECTED_FINALIZATION_PERIOD_TIME_IN_HOURS = 4
export const EXPECTED_FINALIZE_WITHDRAWAL_TIME_IN_SECONDS = 30

export const EXPECTED_ROLL_UP_DEPOSIT_TIME_IN_SECONDS = 15

export const zircuit = defineChain({
  id: env.NEXT_PUBLIC_ZIRCUIT_CHAIN_ID,
  name: 'Zircuit',
  nativeCurrency: {
    decimals: 18,
    name: ETH.symbol,
    symbol: ETH.symbol,
  },
  caipNetworkId: `eip155:${env.NEXT_PUBLIC_ZIRCUIT_CHAIN_ID}`,
  chainNamespace: 'eip155',
  rpcUrls: {
    default: {
      http: [env.NEXT_PUBLIC_ZIRCUIT_NETWORK_RPC_URL],
    },
  },
})

export const l1 = defineChain({
  id: env.NEXT_PUBLIC_L1_CHAIN_ID,
  name: env.NEXT_PUBLIC_L1_CHAIN_NAME,
  nativeCurrency: {
    decimals: 18,
    name: ETH.symbol,
    symbol: ETH.symbol,
  },
  caipNetworkId: `eip155:${env.NEXT_PUBLIC_L1_CHAIN_ID}`,
  chainNamespace: 'eip155',
  rpcUrls: {
    default: {
      http: [env.NEXT_PUBLIC_L1_NETWORK_RPC_URL],
    },
  },
})

export const FULL_ZIRCUIT_NETWORK_NAME = `Zircuit ${capitalize(env.NEXT_PUBLIC_NETWORK)}`

export const chainIdProviderMap = mapValues(
  keyBy({ zircuit, l1 }, 'id'),
  ({ rpcUrls }) => rpcUrls.default.http[0],
)

export const txTypeChainIdMap = {
  [TxType.l2l1]: zircuit.id,
  [TxType.l1l2]: l1.id,
}

export const BRIDGE_MIN_GAS_LIMIT_ETH = 0
export const BRIDGE_MIN_GAS_LIMIT_ERC20 = 80_000

export const L1_LAYER_IDS = [l1.id]
export const L2_LAYER_IDS = [zircuit.id]

export const TRANSACTION_TYPES = {
  withdrawal: 'withdrawal',
  deposit: 'deposit',
}
