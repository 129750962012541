import { useQuery } from '@tanstack/react-query'
import { ethers } from 'ethers'
import React from 'react'
import { useAccount } from 'wagmi'
import { chainIdProviderMap } from 'constants/network'
import { isMultisigWallet } from 'utils/isMultisigWallet'

const WalletContext = React.createContext<{
  isConnectedAddressMultisigWallet: boolean | undefined
  isConnectedMultisigWalletLoading: boolean
}>({
  isConnectedAddressMultisigWallet: false,
  isConnectedMultisigWalletLoading: true,
})

const useWalletContext = () => React.useContext(WalletContext)

type WalletProviderProps = {
  children: React.ReactNode
}

const WalletProvider = ({ children }: WalletProviderProps) => {
  const { address, chain } = useAccount()

  const { data: isConnectedAddressMultisigWallet, isLoading: isConnectedMultisigWalletLoading } =
    useQuery({
      queryKey: ['isMultisigWallet', address, chain],
      enabled: Boolean(address) && Boolean(chain),
      queryFn: () =>
        isMultisigWallet(address!, new ethers.JsonRpcProvider(chainIdProviderMap[chain!.id])),
    })

  const contextValues = React.useMemo(
    () => ({
      isConnectedAddressMultisigWallet,
      isConnectedMultisigWalletLoading,
    }),
    [isConnectedAddressMultisigWallet, isConnectedMultisigWalletLoading],
  )

  return <WalletContext.Provider value={contextValues}>{children}</WalletContext.Provider>
}

export { WalletProvider, useWalletContext }
