import { tagAnatomy } from '@chakra-ui/anatomy'
import { type StyleFunctionProps, createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys)

const baseStyle = definePartsStyle({
  container: {
    borderWidth: 1,
    fontSize: '0.625rem',
    lineHeight: '0.625rem',
    py: 0,
    px: 1.5,
    minH: '1.25rem',
    maxH: '1.25rem',
  },
})

const variants = {
  primary: (props: StyleFunctionProps) => ({
    container: {
      borderColor: mode('zircuitPrimary', 'zircuitLight')(props),
      bg: mode('greenOnBeige', 'darkGreenOnBeige')(props),
      color: mode('zircuitPrimary', 'zircuitLight')(props),
    },
  }),
  secondary: (props: StyleFunctionProps) => ({
    container: {
      borderColor: mode('information', 'information')(props),
      bg: mode('informationBg', 'darkInformationBg')(props),
      color: mode('information', 'information')(props),
    },
  }),
}

export const Tag = defineMultiStyleConfig({
  defaultProps: { variant: 'primary' },
  variants,
  baseStyle,
})
