export const LidoTestnetL2StandardBridge = {
  abi: [
    {
      inputs: [
        {
          internalType: 'address',
          name: 'messenger_',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'l1TokenBridge_',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'l1Token_',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'l2Token_',
          type: 'address',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      inputs: [],
      name: 'ErrorAccountIsZeroAddress',
      type: 'error',
    },
    {
      inputs: [],
      name: 'ErrorAlreadyInitialized',
      type: 'error',
    },
    {
      inputs: [],
      name: 'ErrorDepositsDisabled',
      type: 'error',
    },
    {
      inputs: [],
      name: 'ErrorDepositsEnabled',
      type: 'error',
    },
    {
      inputs: [],
      name: 'ErrorUnauthorizedMessenger',
      type: 'error',
    },
    {
      inputs: [],
      name: 'ErrorUnsupportedL1Token',
      type: 'error',
    },
    {
      inputs: [],
      name: 'ErrorUnsupportedL2Token',
      type: 'error',
    },
    {
      inputs: [],
      name: 'ErrorWithdrawalsDisabled',
      type: 'error',
    },
    {
      inputs: [],
      name: 'ErrorWithdrawalsEnabled',
      type: 'error',
    },
    {
      inputs: [],
      name: 'ErrorWrongCrossDomainSender',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: '_l1Token',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: '_l2Token',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: '_from',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: '_to',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: '_amount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'bytes',
          name: '_data',
          type: 'bytes',
        },
      ],
      name: 'DepositFailed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: '_l1Token',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: '_l2Token',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: '_from',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: '_to',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: '_amount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'bytes',
          name: '_data',
          type: 'bytes',
        },
      ],
      name: 'DepositFinalized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'disabler',
          type: 'address',
        },
      ],
      name: 'DepositsDisabled',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'enabler',
          type: 'address',
        },
      ],
      name: 'DepositsEnabled',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'admin',
          type: 'address',
        },
      ],
      name: 'Initialized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'previousAdminRole',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'newAdminRole',
          type: 'bytes32',
        },
      ],
      name: 'RoleAdminChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address',
        },
      ],
      name: 'RoleGranted',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address',
        },
      ],
      name: 'RoleRevoked',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: '_l1Token',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: '_l2Token',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: '_from',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: '_to',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: '_amount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'bytes',
          name: '_data',
          type: 'bytes',
        },
      ],
      name: 'WithdrawalInitiated',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'disabler',
          type: 'address',
        },
      ],
      name: 'WithdrawalsDisabled',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'enabler',
          type: 'address',
        },
      ],
      name: 'WithdrawalsEnabled',
      type: 'event',
    },
    {
      inputs: [],
      name: 'DEFAULT_ADMIN_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'DEPOSITS_DISABLER_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'DEPOSITS_ENABLER_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'WITHDRAWALS_DISABLER_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'WITHDRAWALS_ENABLER_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'disableDeposits',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'disableWithdrawals',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'enableDeposits',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'enableWithdrawals',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'l1Token_',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'l2Token_',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'from_',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to_',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'amount_',
          type: 'uint256',
        },
        {
          internalType: 'bytes',
          name: 'data_',
          type: 'bytes',
        },
      ],
      name: 'finalizeDeposit',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
      ],
      name: 'getRoleAdmin',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'grantRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'hasRole',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'admin_',
          type: 'address',
        },
      ],
      name: 'initialize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'isDepositsEnabled',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'isInitialized',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'isWithdrawalsEnabled',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'l1Token',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'l1TokenBridge',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'l2Token',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'messenger',
      outputs: [
        {
          internalType: 'contract ICrossDomainMessenger',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'renounceRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'revokeRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes4',
          name: 'interfaceId',
          type: 'bytes4',
        },
      ],
      name: 'supportsInterface',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'l2Token_',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'amount_',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'l1Gas_',
          type: 'uint32',
        },
        {
          internalType: 'bytes',
          name: 'data_',
          type: 'bytes',
        },
      ],
      name: 'withdraw',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'l2Token_',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to_',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'amount_',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'l1Gas_',
          type: 'uint32',
        },
        {
          internalType: 'bytes',
          name: 'data_',
          type: 'bytes',
        },
      ],
      name: 'withdrawTo',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
} as const
