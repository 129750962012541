import { popoverAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const helpers = createMultiStyleConfigHelpers(popoverAnatomy.keys)

const baseStyle = helpers.definePartsStyle((props) => ({
  content: {
    bg: mode('white', 'dark1')(props),
    border: mode('none', '1px solid')(props),
    borderColor: mode('unset', 'darkGrey06')(props),
    py: 0,
    px: 1,
    borderRadius: 10,
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.10)',
  },
}))

export const Popover = helpers.defineMultiStyleConfig({ baseStyle })
