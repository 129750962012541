import { NON_STANDARD_BRIDGE_TOKENS } from 'constants/common'
import { AlphanetL1StandardBridge } from 'deployments/alphanet-sepolia/L1StandardBridge'
import { AlphanetL1StandardBridgeProxy } from 'deployments/alphanet-sepolia/L1StandardBridgeProxy'
import { AlphanetL2StandardBridge } from 'deployments/alphanet-sepolia/L2StandardBridge'
import { AlphanetL2StandardBridgeProxy } from 'deployments/alphanet-sepolia/L2StandardBridgeProxy'
import { AlphanetOptimismPortal } from 'deployments/alphanet-sepolia/OptimismPortal'
import { AlphanetOptimismPortalProxy } from 'deployments/alphanet-sepolia/OptimismPortalProxy'

import { BetanetL1StandardBridge } from 'deployments/betanet-sepolia/L1StandardBridge'
import { BetanetL1StandardBridgeProxy } from 'deployments/betanet-sepolia/L1StandardBridgeProxy'
import { BetanetL2StandardBridge } from 'deployments/betanet-sepolia/L2StandardBridge'
import { BetanetL2StandardBridgeProxy } from 'deployments/betanet-sepolia/L2StandardBridgeProxy'
import { BetanetOptimismPortal } from 'deployments/betanet-sepolia/OptimismPortal'
import { BetanetOptimismPortalProxy } from 'deployments/betanet-sepolia/OptimismPortalProxy'

import { LidoMainnetL1StandardBridge } from 'deployments/lido-mainnet/L1StandardBridge'
import { LidoMainnetL1StandardBridgeProxy } from 'deployments/lido-mainnet/L1StandardBridgeProxy'
import { LidoMainnetL2StandardBridge } from 'deployments/lido-mainnet/L2StandardBridge'
import { LidoMainnetL2StandardBridgeProxy } from 'deployments/lido-mainnet/L2StandardBridgeProxy'

import { LidoTestnetL1StandardBridge } from 'deployments/lido-testnet-sepolia/L1StandardBridge'
import { LidoTestnetL1StandardBridgeProxy } from 'deployments/lido-testnet-sepolia/L1StandardBridgeProxy'
import { LidoTestnetL2StandardBridge } from 'deployments/lido-testnet-sepolia/L2StandardBridge'
import { LidoTestnetL2StandardBridgeProxy } from 'deployments/lido-testnet-sepolia/L2StandardBridgeProxy'

import { MainnetL1StandardBridge } from 'deployments/mainnet/L1StandardBridge'
import { MainnetL1StandardBridgeProxy } from 'deployments/mainnet/L1StandardBridgeProxy'
import { MainnetL2StandardBridge } from 'deployments/mainnet/L2StandardBridge'
import { MainnetL2StandardBridgeProxy } from 'deployments/mainnet/L2StandardBridgeProxy'
import { MainnetOptimismPortal } from 'deployments/mainnet/OptimismPortal'
import { MainnetOptimismPortalProxy } from 'deployments/mainnet/OptimismPortalProxy'

import { TestnetL1StandardBridge } from 'deployments/testnet-sepolia/L1StandardBridge'
import { TestnetL1StandardBridgeProxy } from 'deployments/testnet-sepolia/L1StandardBridgeProxy'
import { TestnetL2StandardBridge } from 'deployments/testnet-sepolia/L2StandardBridge'
import { TestnetL2StandardBridgeProxy } from 'deployments/testnet-sepolia/L2StandardBridgeProxy'
import { TestnetOptimismPortal } from 'deployments/testnet-sepolia/OptimismPortal'
import { TestnetOptimismPortalProxy } from 'deployments/testnet-sepolia/OptimismPortalProxy'

import { env } from 'env.client'

const ALPHANET_CONTRACT = {
  L1StandardBridge: AlphanetL1StandardBridge,
  L1StandardBridgeProxy: AlphanetL1StandardBridgeProxy,
  L2StandardBridge: AlphanetL2StandardBridge,
  L2StandardBridgeProxy: AlphanetL2StandardBridgeProxy,
  OptimismPortal: AlphanetOptimismPortal,
  OptimismPortalProxy: AlphanetOptimismPortalProxy,
  DEFAULT_L1_CONTRACT_ADDRESSES: {
    // Source: https://github.com/zircuit-labs/zkr-monorepo/blob/develop/packages/contracts-bedrock/zr-e2e-test/src/withdrawals.ts#L27
    L1CrossDomainMessenger: '0x95bed4f94D27547EFD8AE058029fA3040FCd43e1',
    L1StandardBridge: AlphanetL1StandardBridgeProxy.address,
    OptimismPortal: AlphanetOptimismPortalProxy.address,
    L2OutputOracle: '0xF314B5575d1b3adB2280897148E38FF4Af64CF2c',
    AddressManager: '0x0000000000000000000000000000000000000000',
    StateCommitmentChain: '0x0000000000000000000000000000000000000000',
    CanonicalTransactionChain: '0x0000000000000000000000000000000000000000',
    BondManager: '0x0000000000000000000000000000000000000000',
  },
}

export const getSmartContracts = (symbol?: string) => {
  if (symbol === NON_STANDARD_BRIDGE_TOKENS.wstETH) {
    if (env.NEXT_PUBLIC_NETWORK === 'testnet') {
      return {
        L1StandardBridge: LidoTestnetL1StandardBridge,
        L1StandardBridgeProxy: LidoTestnetL1StandardBridgeProxy,
        L2StandardBridge: LidoTestnetL2StandardBridge,
        L2StandardBridgeProxy: LidoTestnetL2StandardBridgeProxy,
      }
    }
    if (env.NEXT_PUBLIC_NETWORK === 'mainnet') {
      return {
        L1StandardBridge: LidoMainnetL1StandardBridge,
        L1StandardBridgeProxy: LidoMainnetL1StandardBridgeProxy,
        L2StandardBridge: LidoMainnetL2StandardBridge,
        L2StandardBridgeProxy: LidoMainnetL2StandardBridgeProxy,
      }
    }
  }
  if (env.NEXT_PUBLIC_NETWORK === 'alphanet') {
    return ALPHANET_CONTRACT
  }
  if (env.NEXT_PUBLIC_NETWORK === 'betanet') {
    return {
      L1StandardBridge: BetanetL1StandardBridge,
      L1StandardBridgeProxy: BetanetL1StandardBridgeProxy,
      L2StandardBridge: BetanetL2StandardBridge,
      L2StandardBridgeProxy: BetanetL2StandardBridgeProxy,
      OptimismPortal: BetanetOptimismPortal,
      OptimismPortalProxy: BetanetOptimismPortalProxy,
      DEFAULT_L1_CONTRACT_ADDRESSES: {
        // Source: https://github.com/zircuit-labs/zkr-monorepo/blob/develop/packages/contracts-bedrock/zr-e2e-test/src/withdrawals.ts#L27
        L1CrossDomainMessenger: '0x6A81FdA0F4331EeBD77C2f2D8523dd64225FD302',
        L1StandardBridge: BetanetL1StandardBridgeProxy.address,
        OptimismPortal: BetanetOptimismPortalProxy.address,
        L2OutputOracle: '0xebFD43B98E03B0B8e98DF025B0eb3A3Ba290e437',
        AddressManager: '0x0000000000000000000000000000000000000000',
        StateCommitmentChain: '0x0000000000000000000000000000000000000000',
        CanonicalTransactionChain: '0x0000000000000000000000000000000000000000',
        BondManager: '0x0000000000000000000000000000000000000000',
      },
    }
  }
  if (env.NEXT_PUBLIC_NETWORK === 'testnet') {
    return {
      L1StandardBridge: TestnetL1StandardBridge,
      L1StandardBridgeProxy: TestnetL1StandardBridgeProxy,
      L2StandardBridge: TestnetL2StandardBridge,
      L2StandardBridgeProxy: TestnetL2StandardBridgeProxy,
      OptimismPortal: TestnetOptimismPortal,
      OptimismPortalProxy: TestnetOptimismPortalProxy,
      DEFAULT_L1_CONTRACT_ADDRESSES: {
        // Source: https://github.com/zircuit-labs/zkr-monorepo/blob/develop/packages/contracts-bedrock/zr-e2e-test/src/withdrawals.ts#L27
        L1CrossDomainMessenger: '0x2De7B7364A37fBB35F946cA7175A1b596710b262',
        L1StandardBridge: TestnetL1StandardBridgeProxy.address,
        OptimismPortal: TestnetOptimismPortalProxy.address,
        L2OutputOracle: '0x740C2dac453aEf7140809F80b72bf0e647af8148',
        AddressManager: '0x0000000000000000000000000000000000000000',
        StateCommitmentChain: '0x0000000000000000000000000000000000000000',
        CanonicalTransactionChain: '0x0000000000000000000000000000000000000000',
        BondManager: '0x0000000000000000000000000000000000000000',
      },
    }
  }
  if (env.NEXT_PUBLIC_NETWORK === 'mainnet') {
    return {
      L1StandardBridge: MainnetL1StandardBridge,
      L1StandardBridgeProxy: MainnetL1StandardBridgeProxy,
      L2StandardBridge: MainnetL2StandardBridge,
      L2StandardBridgeProxy: MainnetL2StandardBridgeProxy,
      OptimismPortal: MainnetOptimismPortal,
      OptimismPortalProxy: MainnetOptimismPortalProxy,
      DEFAULT_L1_CONTRACT_ADDRESSES: {
        // Source: https://github.com/zircuit-labs/zkr-monorepo/blob/develop/packages/contracts-bedrock/zr-e2e-test/src/withdrawals.ts#L27
        L1CrossDomainMessenger: '0x2a721cBE81a128be0F01040e3353c3805A5EA091',
        L1StandardBridge: MainnetL1StandardBridgeProxy.address,
        OptimismPortal: MainnetOptimismPortalProxy.address,
        L2OutputOracle: '0x92Ef6Af472b39F1b363da45E35530c24619245A4',
        AddressManager: '0x0000000000000000000000000000000000000000',
        StateCommitmentChain: '0x0000000000000000000000000000000000000000',
        CanonicalTransactionChain: '0x0000000000000000000000000000000000000000',
        BondManager: '0x0000000000000000000000000000000000000000',
      },
    }
  }

  // Fallback
  return ALPHANET_CONTRACT
}
