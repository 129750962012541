import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, type StyleFunctionProps } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const helpers = createMultiStyleConfigHelpers(inputAnatomy.keys)

const BORDER_RADIUS = 8

const sizes = {
  base: helpers.definePartsStyle({ field: { fontSize: '16px', lineHeight: '22px', p: 3.5 } }),
  big: helpers.definePartsStyle({
    field: { fontSize: '24px', lineHeight: '32px', fontWeight: 500, py: 6, px: 4 },
  }),
}

const getBaseStyle = (props: StyleFunctionProps) => ({
  color: mode('dark01', 'white')(props),
  border: '1px solid',
  borderRadius: BORDER_RADIUS,
  borderColor: mode('grey06', 'darkGrey06')(props),
  _placeholder: { color: mode('grey06', 'darkGrey06')(props) },
  _focus: { borderColor: mode('zircuitLight', 'darkZircuitLight')(props) },
  _active: { borderColor: mode('zircuitLight', 'darkZircuitLight')(props) },
  _invalid: { borderColor: mode('error', 'error')(props) },
  _disabled: {
    color: mode('grey03', 'darkGrey03')(props),
    borderColor: mode('grey06', 'darkGrey06')(props),
  },
  _hover: {
    borderColor: mode('beige03', 'darkGrey03')(props),
    _disabled: { borderColor: mode('grey06', 'darkGrey06')(props) },
    _invalid: { borderColor: mode('error', 'error')(props) },
  },
})

const variants = {
  primary: helpers.definePartsStyle((props) => ({
    field: getBaseStyle(props),
  })),
  grouped: helpers.definePartsStyle((props) => ({
    group: getBaseStyle(props),
    field: {
      borderRadius: BORDER_RADIUS,
      borderRight: '1px solid',
      borderColor: mode('grey06', 'grey09')(props),
      _placeholder: { color: mode('grey06', 'darkGrey06')(props) },
      _disabled: {
        opacity: 1,
        bgColor: mode('grey07', 'darkGrey07')(props),
        _placeholder: { color: mode('grey05', 'darkGrey05')(props) },
      },
    },
    addon: {
      px: 3,
      borderRightRadius: BORDER_RADIUS,
      bgColor: mode('white', 'dark1')(props),
    },
  })),
}

export const Input = helpers.defineMultiStyleConfig({
  defaultProps: { size: 'base', variant: 'primary' },
  variants,
  sizes,
})
