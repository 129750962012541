import { progressAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, type StyleFunctionProps } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const helpers = createMultiStyleConfigHelpers(progressAnatomy.keys)

const variants = {
  primary: (props: StyleFunctionProps) => ({
    filledTrack: {
      bgColor: mode('zircuitPrimary', 'zircuitLight')(props),
    },
  }),
  secondary: {
    filledTrack: {
      bgColor: 'error',
    },
  },
}

export const Progress = helpers.defineMultiStyleConfig({
  defaultProps: { variant: 'primary' },
  variants,
})
