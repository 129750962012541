import { bsc } from '@reown/appkit/networks'
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi'
import { createStorage, cookieStorage, http } from '@wagmi/core'
import { createPublicClient } from 'viem'
import { zircuit, l1 } from 'constants/network'
import { env } from 'env.client'

export const wagmiAdapter = new WagmiAdapter({
  projectId: env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,
  networks: [zircuit, l1, bsc],
  // *: https://wagmi.sh/react/guides/ssr
  ssr: true,
  storage: createStorage({
    key: `wagmi.store.zkr-bridge.${env.NEXT_PUBLIC_NETWORK}`,
    storage: cookieStorage,
  }),
  client({ chain }) {
    return createPublicClient({
      chain,
      transport: http(chain.rpcUrls.default.http[0], {
        batch: {
          batchSize: 100,
          wait: 1_000,
        },
      }),
    })
  },
})

export const { wagmiConfig } = wagmiAdapter
