import { modalAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const helpers = createMultiStyleConfigHelpers(modalAnatomy.keys)

const variants = {
  primaryZircuit: helpers.definePartsStyle({
    dialog: {
      minW: { base: '100%', md: 'lg' },
      maxW: { base: '100%', md: 'lg' },
      mb: 0,
      borderBottomLeftRadius: { base: 'none', md: 10 },
      borderBottomRightRadius: { base: 'none', md: 10 },
    },
    header: { px: 8, py: 6 },
    body: { px: 8, py: 6 },
    footer: { px: 8, py: 5, border: 'unset' },
  }),
  infoZircuit: helpers.definePartsStyle((props) => ({
    dialog: {
      minW: { base: 'calc(100% - 32px)', md: 'md' },
      maxW: { base: 'calc(100% - 32px)', md: 'md' },
      mb: { base: 6, xl: 0 },
      mx: { base: 4, xl: 0 },
    },
    header: { px: 5, py: 4 },
    body: {
      px: 5,
      pt: 6,
      pb: 4,
      borderBottom: 'unset',
    },
    footer: {
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      px: 5,
      py: 4,
      bg: mode('white', 'dark1')(props),
    },
  })),
}

const baseStyle = helpers.definePartsStyle((props) => ({
  dialog: {
    alignSelf: { base: 'flex-end', md: 'center' },
    bgColor: mode('grey07', 'darkGrey07')(props),
    borderRadius: 10,
    overflowY: 'auto',
  },
  header: {
    bordertopRadius: 'inherit',
    bgColor: mode('grey07', 'darkGrey07')(props),
  },
  body: {
    border: '1px solid',
    borderColor: mode('grey06', 'darkGrey06')(props),
    bgColor: mode('white', 'dark1')(props),
    borderTopRadius: 10,
  },
  footer: {
    border: '1px solid',
    borderColor: mode('grey06', 'darkGrey06')(props),
    borderTop: 'unset',
    flexDir: { base: 'column', sm: 'row' },
    gap: { base: 2, sm: 0 },
  },
  overlay: {
    backdropFilter: 'blur(3px)',
  },
}))

export const Modal = helpers.defineMultiStyleConfig({
  defaultProps: { variant: 'primaryZircuit' },
  variants,
  baseStyle,
})
