import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { BORDER_RADIUS_PILL } from 'theme/constants'

// eslint-disable-next-line @typescript-eslint/unbound-method
const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys)

const variants = {
  theme: {
    thumb: {
      borderRadius: BORDER_RADIUS_PILL,
    },
    track: {
      borderRadius: BORDER_RADIUS_PILL,
      bg: 'beige02',
      backgroundImage: 'url(../assets/switch_sun.svg)',
      backgroundPosition: 'calc(100% - 0.5rem) center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '1rem 1rem',
      _checked: {
        bg: 'dark1',
        backgroundImage: 'url(../assets/switch_moon.svg)',
        backgroundPosition: '0.5rem center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '1rem 1rem',
      },
    },
  },
}

const sizes = {
  lg: {
    track: {
      p: 1,
    },
  },
}

export const Switch = defineMultiStyleConfig({
  defaultProps: { variant: 'theme', size: 'lg' },
  variants,
  sizes,
})
