import { ApolloProvider } from '@apollo/client'
import { ChakraProvider } from '@chakra-ui/react'
import NextApp from 'next/app'
import type { AppProps, AppContext, AppInitialProps } from 'next/app'
import { appWithTranslation } from 'next-i18next'
import React from 'react'
import type { Config } from 'wagmi'
import { cookieToInitialState, WagmiProvider } from 'wagmi'
import { EmotionCacheProvider } from 'components/EmotionCacheProvider'
import { Seo } from 'components/Seo'
import { ToastContainer } from 'components/Toast'
import { useApollo } from 'hooks/useApollo'
import { hoves } from 'lib/nextjs/font'
import { wagmiConfig } from 'lib/wagmi'
import { AssetProvider } from 'providers/AssetProvider'
import { FeatureFlagsProvider } from 'providers/FeatureFlagsProvider'
import { ReactQueryClientProvider } from 'providers/ReactQueryClientProvider'
import { WalletProvider } from 'providers/WalletProvider'
import { theme } from 'theme'
import type { BaseAppProps, NextPageWithLayout } from 'types/next'

type AppPropsWithLayout = AppProps<BaseAppProps> & {
  Component: NextPageWithLayout<BaseAppProps>
  cookieHeader: string
  nonceHeader: string
}

const App = ({ Component, pageProps, cookieHeader, nonceHeader }: AppPropsWithLayout) => {
  const apolloClient = useApollo(pageProps.initialApolloState)
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <EmotionCacheProvider nonce={nonceHeader}>
      <ChakraProvider theme={theme}>
        <ToastContainer />
        <ApolloProvider client={apolloClient}>
          <WagmiProvider
            config={wagmiConfig as Config}
            reconnectOnMount
            // *: https://wagmi.sh/react/guides/ssr
            {...(cookieHeader && {
              initialState: cookieToInitialState(wagmiConfig as Config, cookieHeader),
            })}
          >
            <ReactQueryClientProvider>
              <FeatureFlagsProvider>
                <AssetProvider>
                  <WalletProvider>
                    <Seo />
                    <main className={hoves.className}>
                      {getLayout(<Component {...pageProps} />)}
                    </main>
                  </WalletProvider>
                </AssetProvider>
              </FeatureFlagsProvider>
            </ReactQueryClientProvider>
          </WagmiProvider>
        </ApolloProvider>
      </ChakraProvider>
    </EmotionCacheProvider>
  )
}

// https://nextjs.org/docs/pages/building-your-application/routing/custom-app#getinitialprops-with-app
App.getInitialProps = async (
  context: AppContext,
): Promise<AppInitialProps & { cookieHeader: string; nonceHeader: string }> => {
  const ctx = await NextApp.getInitialProps(context)

  return {
    ...ctx,
    cookieHeader: context.ctx.req?.headers.cookie ?? '',
    // x-nonce is a custom header which is set by the middleware
    nonceHeader: context.ctx.req?.headers['x-nonce'] as string,
  }
}

const AppWithI18n = appWithTranslation(App)

export default AppWithI18n
